import { Module, Node } from '@msdyn365-commerce-modules/utilities';
import * as React from 'react';
import {
    IHelp,
    IOrderSummary,
    IPaymentMethods,
    IGroup,
    IGroupDelivery,
    IGroups,
    IOrderInformation,
    ISalesLine
} from '@msdyn365-commerce-modules/order-management';
import { IOrderConfirmationViewProps } from '@msdyn365-commerce-modules/order-management/src/modules/order-confirmation/./order-confirmation';
import { RichTextComponent } from '@msdyn365-commerce/core';
import { Address, AttributeValue, TenderLine } from '@msdyn365-commerce/retail-proxy';
import { IOrderConfirmationProps } from '../definition-extensions/order-confirmation.ext.props.autogenerated';

export interface IorderPaymentMethods {
    tenderLines: TenderLine[];
    payment?: IPaymentMethods;
}

export interface IOrderData {
    name: string;
    key?: React.Key | null;
    value?: number;
}

export const OrderConfirmationOrderInfomation: React.FC<IOrderInformation> = ({
    orderInformationProps,
    receiptEmail,
    createdDate,
    channelReferenceId
}) => {
    const cD = createdDate as JSX.Element;

    return (
        <Node {...orderInformationProps}>
            {channelReferenceId}
            <span className='ms-order-confirmation__order-information-created-date'>
                {cD.props.children[0]}
                <span>{cD.props.children[2]}</span>
            </span>

            {receiptEmail}
        </Node>
    );
};
//
export const OrderConfirmationSalesLine: React.FC<ISalesLine> = props => {
    return (
        <Node {...props.salesLineProps}>
            {props.salesLine}
            <div className='quantity-wrapper'>
                Quantity:
                <span className='quantity'>{props.data.salesLine.Quantity}</span>
            </div>
        </Node>
    );
};

export const OrderConfirmationGroupDelivery: React.FC<IGroupDelivery> = ({
    deliveryProps,
    heading,
    count,
    processing,
    address,
    trackingInfo,
    pickupDateTimeslot
}) => (
    <Node {...deliveryProps}>
        <h2>{heading}</h2>
        {address}
        {pickupDateTimeslot}
    </Node>
);

export const OrderConfirmationGroup: React.FC<IGroup> = ({ groupProps, delivery, salesLinesProps, salesLines }) => {
    return (
        <Node {...groupProps}>
            {salesLines && (
                <Node {...salesLinesProps}>
                    {salesLines.map((salesLine: ISalesLine) => {
                        return <OrderConfirmationSalesLine key={salesLine.data.salesLine.LineId} {...salesLine} />;
                    })}
                </Node>
            )}
        </Node>
    );
};

export const OrderConfirmationGroups: React.FC<IGroups> = ({ groupsProps, groups }) => (
    <Node {...groupsProps}>
        {groups.map((group: IGroup, index: number) => (
            <OrderConfirmationGroup key={index} {...group} />
        ))}
    </Node>
);

export const OrderConfirmationOrderSummary: React.FC<IOrderSummary> = ({
    orderSummaryProps,
    heading,
    subtotal,
    shipping,
    tax,
    totalAmount,
    earnedPoints
}) => (
    <Node {...orderSummaryProps}>
        {heading}
        {subtotal}
        {shipping}
        {tax}
        {totalAmount}
        {earnedPoints}
    </Node>
);

export const OrderConfirmationPayment: React.FC<IorderPaymentMethods> = ({ tenderLines, payment }) => {
    if (!payment) {
        return null;
    }

    const { paymentMethodsProps, title } = payment;

    const PaymentMethod = {
        3: 'Credit Card',
        20: 'Gift Card',
        40: 'PayPal'
    };

    return (
        <Node {...paymentMethodsProps}>
            {title}
            <div className='payment-method-type-wrapper'>
                {Object.values(tenderLines).map((item, index, arr) => {
                    return (
                        <span>
                            {PaymentMethod[item.TenderTypeId!]} {arr.length - 1 !== index && <span> + </span>}
                        </span>
                    );
                })}
            </div>
        </Node>
    );
};

export const OrderConfirmationHelp: React.FC<IHelp> = ({ helpProps, needHelpLabel, helpLineNumberLabel, contactNumber }) => (
    <Node {...helpProps}>
        {needHelpLabel}
        {helpLineNumberLabel}
        {contactNumber}
    </Node>
);

const OrderConfirmationView: React.FC<IOrderConfirmationViewProps & IOrderConfirmationProps<{}>> = ({
    heading,
    config,
    resources,
    data: {
        orderHydration: { result }
    },
    moduleProps,
    backToShoppingLink,
    alert,
    loading,
    orderInfomation,
    orderSummary,
    payment,
    help,
    groups,
    context
}) => {
    const {
        paymentStatusHeading,
        paymentStatusValue,
        totalHeading,
        thanksMessage,
        thanksMessageDetail,
        overviewHeading,
        billingAddressHeading
    } = resources;

    const {
        promoCode,
        queryMessage,
        queryMessageClosed,
        queryMessageClosed2,
        thankyouMessage,
        thankyouMessageOff,
        thankyouTitle,
        writeToUs,
        writeToUsLink,
        contactUsMessage
    } = config;

    const salesOrderAttributes: AttributeValue[] = (result?.salesOrder?.SalesLines && result.salesOrder.AttributeValues) || [];
    const saleOrderBillingAttr: AttributeValue | undefined = salesOrderAttributes.find(
        attr => attr.Name === context.app.config.billingAddressAttribute || 'BILLINGADDRESS'
    );
    const address: Address = saleOrderBillingAttr && saleOrderBillingAttr.TextValue && JSON.parse(saleOrderBillingAttr.TextValue);

    const tenderLines: TenderLine[] = result?.salesOrder?.TenderLines || [];
    const saleOrderTenderLines: TenderLine[] = tenderLines.filter(
        (value, index, self) => index === self.findIndex(t => t.TenderTypeId === value.TenderTypeId)
    );

    return (
        <div className='order-confirmation-wrapper'>
            <Module {...moduleProps}>
                {heading}
                {alert}
                {loading}
                <div className='promotion-box'>
                    <div className='thankyou-wrapper'>
                        <h1>{thankyouTitle && <RichTextComponent text={thankyouTitle} />}</h1>

                        {(thankyouMessage || thankyouMessageOff) && (
                            <div className='msg-box'>
                                <p>{thankyouMessage && <RichTextComponent text={thankyouMessage} />}</p>
                                <p className='msg-off'>{thankyouMessageOff && <RichTextComponent text={thankyouMessageOff} />}</p>
                            </div>
                        )}
                    </div>

                    {promoCode && <div className='promo-code-box'>{promoCode && <RichTextComponent text={promoCode} />}</div>}

                    <div className='shopping-box'>{backToShoppingLink}</div>
                </div>
                <div className='order-info-wrapper'>
                    <div className='order-info-child-wrapper with-grey-bg'>
                        <div className='order-info without-grey-bg'>
                            <div className='Overview-box'>
                                <h2>{overviewHeading}</h2>
                            </div>
                            {orderInfomation && <OrderConfirmationOrderInfomation {...orderInfomation} />}
                            <div className='payment-status-box'>
                                <span className='ms-order-confirmation__order-status'>
                                    {paymentStatusHeading}
                                    <span>{paymentStatusValue}</span>
                                </span>
                            </div>

                            {saleOrderTenderLines && <OrderConfirmationPayment payment={payment} tenderLines={saleOrderTenderLines} />}
                        </div>
                    </div>
                    <div className='order-info-child-wrapper'>
                        <div className='order-info'>
                            {groups?.groups[0].delivery && <OrderConfirmationGroupDelivery {...groups?.groups[0].delivery} />}
                        </div>
                        <div className='order-info'>
                            {address && (
                                <>
                                    <h2 className='ms-order-confirmation__group-delivery-heading'> {billingAddressHeading} </h2>
                                    <div className='ms-order-confirmation__address deliveryType-ship'>
                                        <span className='ms-order-confirmation__address-name'>{address.Name}</span>
                                        <span className='ms-order-confirmation__address-street'>{address.Street}</span>
                                        <span className='ms-order-confirmation__address-city'>{address.City} </span>
                                        <span className='ms-order-confirmation__address-zipcode'> {address.ZipCode} </span>
                                        <span className='ms-order-confirmation__address-threeletterisoregionname'>
                                            {address.ThreeLetterISORegionName}
                                        </span>
                                        <span className='ms-order-confirmation__address-phone'>{address.Phone}</span>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className='order-summary-wrapper'>{groups && <OrderConfirmationGroups {...groups} />}</div>
                <div className='payment-summary-wrapper'>
                    <div className='payment-box'>
                        <div className='payment-text-wrapper'>
                            <h3>{totalHeading}</h3>
                            <p>{thanksMessage}</p>
                            <p>{thanksMessageDetail}</p>
                        </div>
                    </div>
                    <div className='payment-box'>{orderSummary && <OrderConfirmationOrderSummary {...orderSummary} />}</div>
                </div>
                <div className='order-contact-wrapper'>
                    <span>{queryMessage && <RichTextComponent text={queryMessage} />}</span>
                    <span className='contact-info-box'>
                        <span>
                            {writeToUs && <RichTextComponent text={writeToUs} />}
                            {writeToUsLink?.linkText && (
                                <a className='terms-condition-links' href={writeToUsLink.linkUrl.destinationUrl}>
                                    {writeToUsLink.linkText && <RichTextComponent text={writeToUsLink.linkText} />}
                                </a>
                            )}
                        </span>
                        <span>{contactUsMessage && <RichTextComponent text={contactUsMessage} />}</span>
                    </span>
                    <span>{queryMessageClosed && <RichTextComponent text={queryMessageClosed} />}</span>
                    <span>{queryMessageClosed2 && <RichTextComponent text={queryMessageClosed2} />}</span>
                </div>
                <div className='order-help-wrapper'>{help && <OrderConfirmationHelp {...help} />}</div>
            </Module>
        </div>
    );
};

export default OrderConfirmationView;
